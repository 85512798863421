<template>
  <div>
    <KTCard title="Invoice List">
      <template v-slot:body>
        <b-row v-if="!!tableSettings">
          <b-col cols="6">
            <b-form-group class="m-2" label-size="sm">
              <template v-if="tableSettings.refresh">
                <b-button size="sm" class="mr-2">
                  <i class="fa fa-sync"></i> Refresh
                </b-button>
              </template>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              class="m-2"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
            >
              <template v-if="tableSettings.filter">
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                      >Clear</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </template>
            </b-form-group>
          </b-col>
        </b-row>

        <b-table
          :per-page="tableSettings.perPage"
          :current-page="tableSettings.currentPage"
          :filter="filter"
          striped
          show-empty
          bordered
          hover
          :items="InvoiceList"
          :fields="fields"
        >
          <template v-slot:cell(unit_course_price)="row">
            <vue-numeric
              type="string"
              read-only
              :currency="globalCurrency"
              separator=","
              v-bind:precision="2"
              v-model="row.item.unit_course_price"
            ></vue-numeric>
          </template>
          <template v-slot:cell(invoice_status_id)="row">
            <span
              :class="{
                'text-warning': row.item.invoice_status_id === 2,
                'text-success': row.item.invoice_status_id === 1,
                'text-danger': row.item.invoice_status_id === 3,
              }"
              >{{ InvoiceStatusConstant(row.item.invoice_status_id) }}</span
            >
          </template>
          <template v-slot:cell(tax)="row">
            <vue-numeric
              type="string"
              read-only
              :currency="globalCurrency"
              separator=","
              v-bind:precision="2"
              v-model="row.item.tax"
            ></vue-numeric>
          </template>
          <template v-slot:cell(total)="row">
            <vue-numeric
              type="string"
              read-only
              :currency="globalCurrency"
              separator=","
              v-bind:precision="2"
              v-model="row.item.total"
            ></vue-numeric>
          </template>
          <template #row-details="row">
            <b-card>
              <b-table
                :per-page="tableSettings.perPage"
                :current-page="tableSettings.currentPage"
                striped
                show-empty
                bordered
                hover
                :items="row.item.invoice_details"
                :fields="invoiceDetailsFields"
              >
                <template v-slot:cell(unit_course_price)="row">
                  <vue-numeric
                    type="string"
                    read-only
                    :currency="globalCurrency"
                    separator=","
                    v-bind:precision="2"
                    v-model="row.item.unit_course_price"
                  ></vue-numeric>
                </template>
                <template v-slot:cell(tax)="row">
                  <vue-numeric
                    type="string"
                    read-only
                    :currency="globalCurrency"
                    separator=","
                    v-bind:precision="2"
                    v-model="row.item.tax"
                  ></vue-numeric>
                </template>
                <template v-slot:cell(total)="row">
                  <vue-numeric
                    type="string"
                    read-only
                    :currency="globalCurrency"
                    separator=","
                    v-bind:precision="2"
                    v-model="row.item.total"
                  ></vue-numeric>
                </template>
                <template v-slot:cell(discount)="row">
                  <vue-numeric
                    type="string"
                    read-only
                    :currency="globalCurrency"
                    separator=","
                    v-bind:precision="2"
                    v-model="row.item.discount"
                  ></vue-numeric>
                </template>
                <template v-slot:cell(fee)="row">
                  <vue-numeric
                    type="string"
                    read-only
                    :currency="globalCurrency"
                    separator=","
                    v-bind:precision="2"
                    v-model="row.item.fee"
                  ></vue-numeric>
                </template>
              </b-table>
            </b-card>
          </template>
          <template v-slot:cell(actions)="row">
            <b-button-group size="sm">
              <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                {{ row.detailsShowing ? "Hide" : "Show" }} Details
              </b-button>
              <b-button
                @click="goPaymentPage(row.item)"
                variant="primary"
                size="sm"
              >
                Payment
              </b-button>
              <!--              <b-button @click="onView(row.item)" variant="secondary" size="sm">-->
              <!--                ViewPDF-->
              <!--              </b-button>-->
              <b-button @click="onDownload(row.item)" variant="info" size="sm">
                Download PDF
              </b-button>
            </b-button-group>
          </template>
        </b-table>

        <b-pagination
          v-if="tableSettings.row > tableSettings.perPage"
          class="pl-0"
          v-model="currentPage"
          :total-rows="tableSettings.rows"
          :per-page="tableSettings.perPage"
        ></b-pagination>
      </template>
    </KTCard>
    <modal-bill-plz></modal-bill-plz>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/modules/breadcrumbs.module";

import { validationMixin } from "vuelidate";
import {
  GET_INVOICE_LIST,
  DOWNLOAD_INVOICE_PDF,
} from "@/core/services/store/modules/clientadmin/billing.module";
import ModalBillPlz from "@/view/pages/clientadmin/billing/modals/ModalBillPlz";
import VueNumeric from "vue-numeric";
import { SYSTEM_CONSTANTS } from "@/core/services/store/modules/ui.module";

export default {
  mixins: [validationMixin],
  components: { ModalBillPlz, VueNumeric },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Client Admin" },
      { title: "Invoice List" },
    ]);

    this.$store.dispatch(GET_INVOICE_LIST, this.defaultPayload);
    this.$store.dispatch(SYSTEM_CONSTANTS);
  },
  validations: {
    form: {},
  },
  computed: {
    tableSettings() {
      const list = this.$store.state.clientadmin_billing.invoiceList;
      let perPage = 20;
      let listLength;
      if (Array.isArray(list) && list.length) {
        listLength = list.length;
      } else {
        listLength = 100;
      }
      return {
        row: listLength,
        perPage: perPage,
        refresh: false,
        filter: true,
      };
    },
    InvoiceList() {
      const list = this.$store.state.clientadmin_billing.invoiceList;
      console.log(list);
      if (Array.isArray(list) && list.length) {
        return list;
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      selectedInvoice: null,
      form: {},
      filter: "",
      currentPage: 1,
      invoiceDetailsFields: [
        {
          key: "trainee_course_id",
          label: "Course ID",
          sortable: false,
        },
        {
          key: "course_name",
          label: "Course Name",
          sortable: false,
        },
        {
          key: "unit_course_price",
          label: "Unit Price",
          sortable: false,
        },
        {
          key: "quantity",
          label: "Quantity",
          sortable: false,
        },
        {
          key: "discount",
          label: "Discount",
          sortable: false,
        },
        {
          key: "tax",
          label: "Tax",
          sortable: false,
        },
        {
          key: "fee",
          label: "Fee",
          sortable: false,
        },
        {
          key: "total",
          label: "Total",
          sortable: false,
        },
      ],
      fields: [
        {
          key: "invoice_id",
          label: "Invoice ID",
          sortable: true,
        },
        {
          key: "invoice_unique_ref",
          label: "Ref",
          sortable: false,
        },
        {
          key: "invoice_status_id",
          label: "Status",
          sortable: false,
        },
        {
          key: "tax",
          label: "Tax",
          sortable: false,
        },
        {
          key: "total",
          label: "Total",
          sortable: false,
        },
        {
          key: "actions",
          class: "text-right",
        },
      ],
    };
  },

  methods: {
    goPaymentPage(invoice) {
      this.selectedInvoice = invoice;

      this.$router.push({
        name: "make-payment-page",
        params: {
          invoiceId: this.selectedInvoice.invoice_id,
          bills: this.selectedInvoice.bills,
        },
      });
    },
    onView(invoice) {
      // assign selected trainee
      this.selectedInvoice = invoice;
    },
    createBill() {},
    onDownload(invoice) {
      // assign selected trainee
      this.selectedInvoice = invoice;

      // download pdf file
      const payload = {
        invoice_id: this.selectedInvoice.invoice_id,
      };
      this.$store.dispatch(DOWNLOAD_INVOICE_PDF, payload);
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.submitForm();
    },
  },
};
</script>
