<template>
  <b-modal
    id="ModalBillPlz"
    :visible="modalVisibility(modalId)"
    :title="`Payment`"
    @show="onShow()"
    @hidden="resetModal()"
    @ok="onOk"
    size="md"
    no-close-on-backdrop
    ok-title="Submit"
  >
    <b-row v-if="!!tableSettings">
      <b-col cols="3">
        <b-form-group class="m-2" label-size="sm">
          <template v-if="tableSettings.refresh">
            <b-button size="sm" class="mr-2">
              <i class="fa fa-sync"></i> Refresh
            </b-button>
          </template>
        </b-form-group>
      </b-col>
      <b-col cols="9">
        <b-form-group
          class="m-2"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
        >
          <template v-if="tableSettings.filter">
            <b-input-group size="sm">
              <b-form-input
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Clear</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </template>
        </b-form-group>
      </b-col>
    </b-row>

    <b-table
      :per-page="tableSettings.perPage"
      :current-page="tableSettings.currentPage"
      :filter="filter"
      striped
      bordered
      show-empty
      hover
      :items="BillList"
      :fields="fields"
    >
      <template v-slot:cell(actions)="row">
        <b-button-group size="sm">
          <b-button @click="onPay(row.item)" variant="primary" size="sm">
            Pay
          </b-button>
        </b-button-group>
      </template>
    </b-table>

    <b-pagination
      v-if="tableSettings.row > tableSettings.perPage"
      class="pl-0"
      v-model="currentPage"
      :total-rows="tableSettings.rows"
      :per-page="tableSettings.perPage"
    ></b-pagination>

    {{ form }}
  </b-modal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { SET_MODAL_STATE } from "@/core/services/store/modules/ui.module";
import SystemHelper from "@/core/services/systemhelper.service";
import {
  CHECKOUT_COMPANY_COURSE,
  GET_COMPANY_TRAINEE_COURSE_LIST,
} from "@/core/services/store/modules/clientadmin/course.module";
import { CREATE_BILL } from "@/core/services/store/modules/clientadmin/billing.module";

export default {
  mixins: [validationMixin],
  components: {},
  data() {
    return {
      filter: "",
      currentPage: 1,
      readOnly: true,
      modalId: "ModalBillPlz",
      isBusy: true,
      form: {},
      fields: [],
    };
  },
  validations: {
    form: {},
  },
  computed: {
    tableSettings() {
      const list = this.$store.state.clientadmin_billing.billStatus;
      let perPage = 20;
      let listLength;
      if (Array.isArray(list) && list.length) {
        listLength = list.length;
      } else {
        listLength = 100;
      }
      return {
        row: listLength,
        perPage: perPage,
        refresh: false,
        filter: true,
        pagination: true,
      };
    },
    BillList() {
      const list = this.$store.state.clientadmin_billing.billStatus;
      if (Array.isArray(list) && list.length) {
        return list;
      } else {
        return [];
      }
    },
  },
  methods: {
    textApproveStatus(id) {
      let txt;
      switch (id) {
        case 1:
          txt = "Approved";
          break;
        case 2:
          txt = "Pending";
          break;
        case 3:
          txt = "Rejected";
          break;
      }
      return txt;
    },
    onShow() {
      console.log("showing");
      console.log(this.$parent.selectedInvoice);
      this.form = this.$parent.selectedInvoice;

      let payload = {
        invoice_id: this.$parent.selectedInvoice.invoice_id,
      };
      this.$store.dispatch(CREATE_BILL, payload);
    },

    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      } else {
        console.log("submit");

        let payload = {
          selected_courses: JSON.stringify([this.form]),
        };
        SystemHelper.confirmationDialogHandler(
          { title: "Confirmation", html: "Purchase course?" },
          { action: CHECKOUT_COMPANY_COURSE, payload: payload },
          [
            {
              action: GET_COMPANY_TRAINEE_COURSE_LIST,
              payload: this.defaultPayload,
            },
          ],
          this.modalId
        );
      }
    },
    onOk(bvModalEvent) {
      console.log(bvModalEvent);
      bvModalEvent.preventDefault();

      // trigger submit
      this.onSubmit();
    },
    resetModal() {
      this.$v.form.$reset();
      this.$store.dispatch(SET_MODAL_STATE, null);
    },
  },
};
</script>
